import { Injectable } from "@angular/core";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";

import { ApiFourmiService } from "../../shared/api-fourmi.service";

@Injectable()
export class UserService {
    API;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService
    ) {
        this.API = apiFourmi.get();
    }

    activate(user) {
        return this.autHttp
            .post(`${this.API}/user-activate`, user)
            .map((data) => data.json().data);
    }

    list() {
        return this.autHttp
            .get(`${this.API}/user`)
            .map((data) => data.json().data);
    }

    listParams(params) {
        return this.autHttp
            .get(`${this.API}/user`, params)
            .map((data) => data.json());
    }

    get(id: number) {
        return this.autHttp
            .get(`${this.API}/user/${id}`)
            .map((data) => data.json().data);
    }

    getFullName(id: number) {
        return this.autHttp
            .get(`${this.API}/user-fullname/?user_id=${id}`)
            .map((data) => data.json().data);
    }

    store(user) {
        return this.autHttp
            .post(`${this.API}/user`, user)
            .map((data) => data.json().data);
    }

    update(id, user) {
        return this.autHttp
            .put(`${this.API}/user/${id}`, user)
            .map((data) => data.json().data);
    }

    transfer(info) {
        return this.autHttp
            .post(`${this.API}/user-transfer`, info)
            .map((data) => data.json().data);
    }
}
