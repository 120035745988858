import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { MeetingService } from './meeting.service';

@Injectable()
export class MeetingResolverService {

    constructor(
        private stateService: MeetingService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = +route.paramMap.get('id');

        return this.stateService.get(id).map(data => {
            if (data) {
                return data;
            } else { // id not found
                this.router.navigate(['/state']);
                return null;
            }
        });
    }

}
