import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Injectable()
export class ServiceExternalService {
    API;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    list() {
        return this.autHttp.get(`${this.API}/service-external`)
            .map(data => data.json().data);
    }

    listActive() {
        return this.autHttp.get(`${this.API}/service-external?status_id=1`)
            .map(data => data.json().data);
    }

    get(id: number) {
        return this.autHttp.get(`${this.API}/service-external/${id}`)
            .map(data => data.json().data);
    }

    store(serviceExternal) {
        return this.autHttp.post(`${this.API}/service-external`, serviceExternal)
            .map(data => data.json().data);
    }

    update(id, serviceExternal) {
        return this.autHttp.put(`${this.API}/service-external/${id}`, serviceExternal)
            .map(data => data.json().data);
    }
}
